
.emptyProgressBar {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 100px;
  height: 2px;
  overflow: hidden;
  position: relative;
}
.fillingProgressBar {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6), #fff);
  border-radius: 100px;
  height: 100%;
  width: 100%;
  position: absolute;
}
.mainProgressBarDiv {
  margin-right: 4px;
  width: 100%;
}
