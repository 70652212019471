@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgWxP.ttf)
    format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5vAw.ttf)
    format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf)
    format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9vAw.ttf)
    format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAw.ttf)
    format("truetype");
}

@font-face {
  font-family: "Futura PT Book";
  src: url("../assets/fonts/FuturaPT-Book.woff2") format("woff2"),
    url("../assets/fonts/FuturaPT-Book.woff") format("woff"),
    url("../assets/fonts/FuturaPT-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura PT";
  src: url("../assets/fonts/FuturaPT-Medium.woff2") format("woff2"),
    url("../assets/fonts/FuturaPT-Medium.woff") format("woff"),
    url("../assets/fonts/FuturaPT-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura PT Demi";
  src: url("../assets/fonts/FuturaPT-Demi.woff2") format("woff2"),
    url("../assets/fonts/FuturaPT-Demi.woff") format("woff"),
    url("../assets/fonts/FuturaPT-Demi.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura PT";
  src: url("../assets/fonts/FuturaPT-Heavy.woff2") format("woff2"),
    url("../assets/fonts/FuturaPT-Heavy.woff") format("woff"),
    url("../assets/fonts/FuturaPT-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta CY";
  src: url("../assets/fonts/AvertaCY-Regular.woff2") format("woff2"),
    url("../assets/fonts/AvertaCY-Regular.woff") format("woff"),
    url("../assets/fonts/AvertaCY-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta CY";
  src: url("../assets/fonts/AvertaCY-Semibold.woff2") format("woff2"),
    url("../assets/fonts/AvertaCY-Semibold.woff") format("woff"),
    url("../assets/fonts/AvertaCY-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.page-loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 0.9;
}
.page-loader .spin {
  width: 48px;
  height: 48px;
}
.page-loader .spin svg {
  width: inherit;
  height: inherit;
}
.page-loader .spin {
  transform-origin: center;
  animation: effect-spin infinite 1s linear;
}
@keyframes effect-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  -webkit-font-smoothing: auto;
}
.matadot-live-chat-123789-chatbox-root {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.matadot-live-chat-123789-chatbox-body {
  height: 100%;
  margin: 0;
}
.matadot-live-chat-123789-chatbox-html {
  height: 100%;
}
.matadot-live-chat-123789-chatbox-preview {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Futura PT", sans-serif;
  overflow: auto;
}
.matadot-live-chat-123789-chatbox-preview.matadot-live-chat-123789-light-chatbox-preview-border
  .matadot-live-chat-123789-chatbox {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.matadot-live-chat-123789-chatbox-preview .matadot-live-chat-123789-chatbox {
  width: 100%;
  height: 100% !important;
  box-shadow: 4px 26px 27px rgba(221, 224, 231, 0.76);
  box-sizing: border-box;
  position: relative;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox.matadot-live-chat-123789-chatbox-current-view-2 {
  animation: fadeIn1 1s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.matadot-live-chat-123789-chatbox-current-view-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox.matadot-live-chat-123789-chatbox-current-view-1backView {
  animation: fadeIn2 1s;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top {
  position: relative;
  background-color: #a3a6be;
  padding: 17px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-h2 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: #fff;
  text-align: left;
  min-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  line-height: normal;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-help-text {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #fff;
  text-align: left;
  margin-bottom: 0;
  margin-top: 6px;
  font-weight: 400;
  height: 16px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-help-text
  svg {
  margin-right: 8px;
  fill: #fff;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-help-text
  .matadot-live-chat-123789-help-text {
  position: relative;
  margin-top: 0;
  bottom: 1px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-question-input {
  position: relative;
  margin-top: 15px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 52px;
  font-size: 14px;
  margin-bottom: 0;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-question-input
  .matadot-live-chat-123789-input {
  background-color: transparent;
  border: none;
  height: 100%;
  color: #fff;
  width: 100%;
  padding: 0 20px;
  font-size: 14px;
  padding-right: 40px;
  box-sizing: border-box;
  font-weight: 500;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-question-input
  .matadot-live-chat-123789-input::placeholder {
  color: #fff;
  font-weight: 500;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-question-input
  .matadot-live-chat-123789-input:focus {
  outline: none;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-question-input
  svg {
  position: absolute;
  right: 15px;
  top: 17px;
  fill: #fff;
  cursor: pointer;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matador-live-chat-123789-close-btn {
  position: absolute;
  top: 24px;
  right: 17px;
  cursor: pointer;
  line-height: normal;
  display: flex;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-h2-with-icon {
  display: flex;
  align-items: center;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-h2-with-icon
  .matadot-live-chat-123789-back-view {
  display: flex;
  align-items: center;
  cursor: "pointer";
  width: fit-content;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-h2-with-icon
  .matadot-live-chat-123789-back-view
  svg {
  cursor: pointer;
  position: relative;
  top: 1px;
  margin-right: 10px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle {
  background-color: #f5f5f5;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 20px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-questons-scrollbars {
  overflow: auto;
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-questons-scrollbars::-webkit-scrollbar,
.matadot-live-chat-123789-chatbox-preview::-webkit-scrollbar {
  width: 6px;
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: 100px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-questons-scrollbars::-webkit-scrollbar-track,
.matadot-live-chat-123789-chatbox-preview::-webkit-scrollbar-track {
  background-color: transparent;
  margin-left: -10px;
  margin-bottom: 20px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-questons-scrollbars::-webkit-scrollbar-thumb,
.matadot-live-chat-123789-chatbox-preview::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.2);
}

.matadot-live-chat-123789-chatbox-preview::-webkit-scrollbar {
  display: none;
}

.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-help-text {
  padding: 0 10px 15px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  min-height: 19px;
  white-space: nowrap;
  letter-spacing: -0.4px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-help-text
  svg {
  margin-right: 5px;
  fill: #333;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-question-button {
  padding: 0px 16px;
  text-align: center;
  color: #000000;
  opacity: 0.9;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation-fill-mode: both;
  animation-duration: 1.5s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 10px;
  height: 46px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-question-button
  + .matadot-live-chat-123789-question-button {
  margin-top: 8px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes zoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle.matadot-live-chat-123789-chatbox-middle-two {
  height: calc(100% - 263px);
  overflow: auto;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle.matadot-live-chat-123789-chatbox-middle-two
  .matadot-live-chat-123789-questons-scrollbars {
  padding-bottom: 0;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle.matadot-live-chat-123789-chatbox-middle-three {
  height: calc(100% - 123px);
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle.matadot-live-chat-123789-chatbox-middle-three
  .matadot-live-chat-123789-questons-scrollbars {
  padding-bottom: 0;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper {
  max-width: 90%;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  + .matadot-live-chat-123789-chat-message-wrappper {
  margin-top: 20px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: auto;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message {
  background-color: #a3a6be;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-text {
  color: #fff;
  text-align: right;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-date {
  font-size: 10px;
  color: #fff;
  opacity: 0.5;
  text-align: right;
  font-weight: 500;
  margin-bottom: 0px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-phone {
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
  text-align: right;
  font-weight: 500;
  margin-bottom: 6px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message-received {
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #a3a6be;
  margin-top: 4px;
  font-weight: 500;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message-received
  svg {
  margin-right: 3px;
  height: 9px;
  width: 15px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-left {
  display: flex;
  justify-content: flex-start;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-left
  .matadot-live-chat-123789-message-with-image {
  animation: slideMatadorAnimation 0.5s forwards;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-left
  .matadot-live-chat-123789-message {
  background-color: #fff;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-left
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-phone {
  font-size: 12px;
  color: #a3a6be;
  font-weight: 500;
  margin-bottom: 6px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message-with-image {
  animation: slideMatadorAnimation 0.5s forwards;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message {
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-phone {
  border-radius: 13px 13px 0 0;
  text-align: center;
  background-color: rgba(163, 166, 190, 0.3);
  padding: 5px;
  width: 100%;
  margin-bottom: 0px;
  color: #fff;
  font-weight: bold;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-h2 {
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  margin-top: 9px;
  margin-bottom: 0;
  padding: 0 20px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-text {
  text-align: center;
  font-size: 12px;
  padding: 7px 10px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-check-wrapper {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a3a6be;
  margin-top: 6px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-check-wrapper
  svg {
  width: 10px;
  height: 10px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 13px;
  word-break: break-word;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-text {
  color: #474657;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  white-space: pre-line;
  line-height: 19px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-date {
  font-size: 10px;
  color: #a3a6be;
  font-weight: 500;
  margin-top: 2px;
  text-transform: lowercase;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message-with-image {
  display: flex;
  align-items: flex-end;
  position: relative;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message-with-image
  .matadot-live-chat-123789-img-wrapper {
  background-color: #d0d1dd;
  min-width: 36px;
  max-width: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message-with-image
  .matadot-live-chat-123789-img-wrapper
  .matadot-live-chat-123789-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message {
  background-color: #fff;
  padding: 10px;
  position: relative;
  padding-bottom: 0;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .react-tel-input
  .selected-flag:hover,
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .react-tel-input
  .selected-flag:focus {
  background-color: transparent;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .flag-dropdown.open {
  background: transparent;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .react-tel-input
  .flag-dropdown.open
  .selected-flag {
  background: transparent;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .react-tel-input
  .flag-dropdown {
  border-radius: 100px 0 0 100px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  svg {
  position: absolute;
  top: 27px;
  z-index: 1;
  left: 23px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-main-input {
  height: 54px;
  border: none;
  background-color: #f4f7fc !important;
  border-radius: 100px;
  padding-right: 110px !important;
  padding-left: 50px !important;
  width: 100%;
  font-size: 14px;
  box-shadow: none;
  margin: 0 !important;
  box-sizing: border-box;
}
@media (max-width: 576px) {
  .matadot-live-chat-123789-chatbox-preview
    .matadot-live-chat-123789-chatbox
    .matadot-live-chat-123789-write-text-message
    .matadot-live-chat-123789-main-input {
    font-size: 16px !important;
  }
}

.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-main-input:focus {
  outline: none;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-main-button {
  border-radius: 100px;
  position: absolute;
  top: 17px;
  right: 17px;
  color: #fff;
  transition: background-color 0.3s ease-in-out;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  height: 40px;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-main-button:focus {
  outline: none;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom {
  background-color: #fff;
  padding: 16px 52px;
  color: #5c5c5c;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom
  p {
  text-align: center;
  opacity: 0.9;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom
  p:first-child {
  font-size: 16px;
  color: #5c5c5c;
  opacity: 0.9;
  padding-bottom: 8px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom
  p
  .matadot-live-chat-123789-app-name {
  font-weight: bold;
  color: #2d2d66;
  display: flex;
  align-items: center;
  margin-left: 3px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom
  p
  .matadot-live-chat-123789-app-name
  svg {
  width: 95px;
  height: 25px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom
  p
  .matadot-live-chat-123789-normal-text {
  font-weight: 400;
  color: #5c5c5c;
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-right: 3px;
  text-decoration: none;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-dotsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: zoominoutsinglefeatured 2s forwards;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-dotsContainer
  svg {
  margin: auto;
  border-radius: 13px 13px 13px 0;
  height: 40px;
  width: 60px;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-dotsContainerTwo {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: zoominoutsinglefeatured 2s forwards;
}
.matadot-live-chat-123789-chatbox-preview
  .matadot-live-chat-123789-dotsContainerTwo
  svg {
  margin: auto;
  border-radius: 13px 13px 13px 0;
  height: 40px;
  width: 60px;
}
@keyframes slideLeftMatadorAnimationOne {
  0% {
    left: -1000px;
  }
  100% {
    left: 0;
  }
}
@keyframes slideLeftMatadorAnimationTwo {
  0% {
    left: -1000px;
  }
  100% {
    left: 0;
  }
}
@keyframes slideLeftMatadorAnimationThree {
  0% {
    right: -1000px;
  }
  100% {
    right: 0;
  }
}
@keyframes slideMatadorAnimation {
  0% {
    left: -100px;
  }
  100% {
    left: 0;
  }
}
@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.matadot-live-chat-123789-light-chatbox-preview {
  width: 95%;
  height: 100%;
  font-family: "Roboto", sans-serif;
  margin: 0 auto;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox.matadot-live-chat-123789-chatbox-current-view-2 {
  animation: fadeIn1 1s;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox.matadot-live-chat-123789-chatbox-current-view-2
  input {
  text-indent: 42px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox.matadot-live-chat-123789-chatbox-current-view-1backView {
  animation: fadeIn2 1s;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top {
  position: relative;
  border-radius: 4px;
  padding: 13px;
  background-color: #fff;
  margin-top: 3px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-h2 {
  color: #2d2d66;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  text-align: left;
  min-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  line-height: normal;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matador-live-chat-123789-close-btn {
  position: absolute;
  top: 19px;
  right: 17px;
  cursor: pointer;
  line-height: normal;
  display: flex;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-h2-with-icon {
  display: flex;
  align-items: center;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-h2-with-icon
  .matadot-live-chat-123789-back-view {
  display: flex;
  align-items: center;
  cursor: "pointer";
  width: fit-content;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-top
  .matadot-live-chat-123789-h2-with-icon
  .matadot-live-chat-123789-back-view
  svg {
  cursor: pointer;
  position: relative;
  top: 0.5px;
  margin-right: 10px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle {
  padding: 10px;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-questons-scrollbars {
  overflow: auto;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-questons-scrollbars::-webkit-scrollbar {
  width: 6px;
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: 100px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-questons-scrollbars::-webkit-scrollbar-track {
  background-color: transparent;
  margin-left: -10px;
  margin-bottom: 20px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-questons-scrollbars::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.2);
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-question-button {
  margin: 0 10px;
  padding: 10px 20px;
  border: 1px solid #a3a6be;
  border-radius: 4px;
  text-align: center;
  color: #a3a6be;
  font-size: 14px;
  background-color: #fff;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-fill-mode: both;
  animation-duration: 1.5s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  word-break: break-word;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-question-button
  + .matadot-live-chat-123789-question-button {
  margin-top: 8px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes zoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle.matadot-live-chat-123789-chatbox-middle-two {
  max-height: calc(100% - 120px);
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle.matadot-live-chat-123789-chatbox-middle-two
  .matadot-live-chat-123789-questons-scrollbars {
  padding-bottom: 0;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle.matadot-live-chat-123789-chatbox-middle-three
  .matadot-live-chat-123789-questons-scrollbars {
  padding-bottom: 0;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper {
  max-width: 90%;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  + .matadot-live-chat-123789-chat-message-wrappper {
  margin-top: 20px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: auto;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message {
  background-color: #a3a6be;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-text {
  color: #fff;
  text-align: right;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-date {
  font-size: 10px;
  color: #fff;
  opacity: 0.5;
  text-align: right;
  font-weight: 500;
  margin-bottom: 0px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-phone {
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
  text-align: right;
  font-weight: 500;
  margin-bottom: 6px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message-received {
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #a3a6be;
  margin-top: 4px;
  font-weight: 500;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-right
  .matadot-live-chat-123789-message-received
  svg {
  margin-right: 3px;
  height: 9px;
  width: 15px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-left {
  display: flex;
  justify-content: flex-start;
  margin-right: auto;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-left
  .matadot-live-chat-123789-message-with-image {
  animation: slideMatadorAnimation 0.5s forwards;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-left
  .matadot-live-chat-123789-message {
  background-color: #fff;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-left
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-phone {
  font-size: 12px;
  color: #a3a6be;
  font-weight: 500;
  margin-bottom: 6px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message-with-image {
  animation: slideMatadorAnimation 0.5s forwards;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message {
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-phone {
  border-radius: 13px 13px 0 0;
  text-align: center;
  background-color: rgba(163, 166, 190, 0.3);
  padding: 5px;
  width: 100%;
  margin-bottom: 0px;
  color: #fff;
  font-weight: bold;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-h2 {
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  margin-top: 9px;
  margin-bottom: 0;
  padding: 0 20px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-text {
  text-align: center;
  font-size: 12px;
  color: #a3a6be;
  padding: 7px 10px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-check-wrapper {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a3a6be;
  margin-top: 6px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper.matadot-live-chat-123789-get-message
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-check-wrapper
  svg {
  width: 10px;
  height: 10px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 13px;
  word-break: break-word;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-text {
  color: #474657;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  white-space: pre-line;
  line-height: 19px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message
  .matadot-live-chat-123789-date {
  font-size: 10px;
  color: #a3a6be;
  font-weight: 500;
  margin-top: 2px;
  text-transform: lowercase;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message-with-image {
  display: flex;
  align-items: flex-end;
  position: relative;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message-with-image
  .matadot-live-chat-123789-img-wrapper {
  background-color: #d0d1dd;
  min-width: 36px;
  max-width: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle
  .matadot-live-chat-123789-chat-message-wrappper
  .matadot-live-chat-123789-message-with-image
  .matadot-live-chat-123789-img-wrapper
  .matadot-live-chat-123789-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message {
  background-color: #fff;
  padding: 10px;
  position: relative;
  padding-bottom: 0;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  svg {
  position: absolute;
  top: 27px;
  z-index: 1;
  left: 23px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-main-input {
  height: 54px;
  border: none;
  background-color: #f4f7fc !important;
  border-radius: 100px;
  padding-right: 110px !important;
  padding-left: 10px !important;
  width: 100%;
  font-size: 14px;
  box-shadow: none;
  margin: 0 !important;
  box-sizing: border-box;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-main-input:focus {
  outline: none;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-main-button {
  border-radius: 100px;
  position: absolute;
  top: 17px;
  right: 17px;
  color: #fff;
  transition: background-color 0.3s ease-in-out;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  height: 40px;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-main-button:focus {
  outline: none;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom {
  background-color: #fff;
  padding: 10px 20px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom
  p {
  text-align: center;
  color: #a3a6be;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom
  p:first-child {
  font-size: 12px;
  padding-bottom: 3px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom
  p
  .matadot-live-chat-123789-app-name {
  font-weight: bold;
  color: #2d2d66;
  display: flex;
  align-items: center;
  margin-left: 3px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom
  p
  .matadot-live-chat-123789-app-name
  svg {
  width: 56.8px;
  height: 13.14px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-bottom
  p
  .matadot-live-chat-123789-normal-text {
  font-weight: 400;
  color: #a3a6be;
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-right: 3px;
  text-decoration: none;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-chatbox-middle-light-theme
  .matadot-live-chat-123789-chatbox-middle-light-theme-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 13px;
  margin-top: 3px;
  font-size: 14px;
  color: #2d2d66;
  font-weight: 400;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  min-height: 45px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message {
  padding: 7px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
  font-size: 9px;
  text-align: center;
  border-radius: 4px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-chatbox-bottom {
  padding: 8px 0 3px 0;
  color: #a3a6be;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-chatbox-bottom
  a {
  color: #706aff;
  text-decoration: none;
  padding-left: 3px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-chatbox-bottom
  a:hover {
  color: #706aff;
  text-decoration: none;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matador-live-chat-123789-input-with-button {
  display: flex;
  justify-content: space-between;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matador-live-chat-123789-input-with-button
  .matadot-live-chat-123789-main-button:disabled {
  background-color: #d1d2de !important;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matador-live-chat-123789-input-with-button
  .matadot-live-chat-123789-main-button {
  border-radius: 4px;
  padding: 0;
  width: 36px;
  height: 36px;
  min-width: 36px;
  max-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  right: 0;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matador-live-chat-123789-input-with-button
  .matadot-live-chat-123789-main-button
  svg {
  position: relative;
  top: 0;
  left: 0;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matador-live-chat-123789-input-with-button
  .matadot-live-chat-123789-main-button.disabled {
  background-color: #706aff;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matador-live-chat-123789-input-with-button
  .matador-live-chat-123789-input-with-icon {
  border: 1px solid #efefef;
  background-color: #f5f5f5;
  border-radius: 4px;
  height: 36px;
  margin-right: 7px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 6px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matador-live-chat-123789-input-with-button
  .matador-live-chat-123789-input-with-icon
  .matador-live-chat-123456-icon-with-code {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #747b95;
  font-weight: 400;
  background-color: #ffffff;
  padding: 0px 10px;
  height: 26px;
  border: 1px solid #efefef;
  border-radius: 4px;
  margin-right: 10px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matador-live-chat-123789-input-with-button
  .matador-live-chat-123789-input-with-icon
  .matador-live-chat-123456-icon-with-code
  svg {
  position: relative;
  top: 0;
  left: 0;
  margin-right: 7px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matador-live-chat-123789-input-with-button
  .matador-live-chat-123789-input-with-icon
  .matadot-live-chat-123789-main-input {
  height: 100%;
  background-color: transparent !important;
  border-radius: 0;
  padding-left: 00px !important;
  padding-right: 10px !important;
  color: #747b95;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-last-view {
  display: flex;
  align-items: center;
  min-height: 57px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-last-view
  svg {
  position: relative;
  top: 0;
  left: 0;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-last-view
  .matadot-live-chat-123789-last-view-text {
  margin-left: 10px;
  text-align: left;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-last-view
  .matadot-live-chat-123789-last-view-text
  h2 {
  margin-top: 0;
  margin-bottom: 0px;
  color: #2d2d66;
  font-size: 15px;
  font-weight: 300;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-chatbox
  .matadot-live-chat-123789-write-text-message
  .matadot-live-chat-123789-last-view
  .matadot-live-chat-123789-last-view-text
  h3 {
  margin-top: 0;
  margin-bottom: 0px;
  color: #2d2d66;
  font-size: 13px;
  font-weight: 500;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-dotsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: zoominoutsinglefeatured 2s forwards;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-dotsContainer
  svg {
  margin: auto;
  border-radius: 13px 13px 13px 0;
  height: 40px;
  width: 60px;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-dotsContainerTwo {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: zoominoutsinglefeatured 2s forwards;
}
.matadot-live-chat-123789-light-chatbox-preview
  .matadot-live-chat-123789-dotsContainerTwo
  svg {
  margin: auto;
  border-radius: 13px 13px 13px 0;
  height: 40px;
  width: 60px;
}
@keyframes slideLeftMatadorAnimationOne {
  0% {
    left: -1000px;
  }
  100% {
    left: 0;
  }
}
@keyframes slideLeftMatadorAnimationTwo {
  0% {
    left: -1000px;
  }
  100% {
    left: 0;
  }
}
@keyframes slideLeftMatadorAnimationThree {
  0% {
    right: -1000px;
  }
  100% {
    right: 0;
  }
}
@keyframes slideMatadorAnimation {
  0% {
    left: -100px;
  }
  100% {
    left: 0;
  }
}
@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
