.darkBG {
  background-color: #00000073;
  inset: 0;
  z-index: 0;
  position: fixed;
  height: 100%;
  width: 100%;
}

.popup_main-container {
  font-family: "Averta CY";
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: transparent;
  width: 489px;
  max-width: calc(100% - 20px);
}

.popup_main-container--image {
  width: auto;
}

.popup_main-container .popup_container {
  display: flex;
}

.popup_main-container .popup_container .popup_right-side {
  flex: 1 1 40.073529411%;
  display: flex;
  position: relative;
  border-radius: 0px 8px 8px 0px;
}

.popup_main-container .popup_container .popup_right-side img {
  width: 100%;
  height: auto;
}

.popup_main-container .popup_container .popup_right-side .overlay {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.2;
  border-radius: 0px 8px 8px 0px;
}

.popup_main-container .popup_container .popup_left-side {
  flex: 1 1 59.926470589%;
  padding-left: 84px;
  padding-bottom: 25px;
  padding-top: 138px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.popup_main-container .popup_container .popup_left-side .popup_subtitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2fac65;
  margin-bottom: 8px;
}

.popup_main-container .popup_container .popup_left-side .popup_header {
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  color: #0e0e0e;
  margin-bottom: 4px;
}

.popup_main-container .popup_container .popup_left-side .popup_description {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #77808d;
  margin-bottom: 26px;
  max-width: 235px;
}

.popup_main-container .popup_container .popup_left-side .popup_button {
  background-color: #2fac65;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  width: 321px;
  height: 46px;
  border: none;
  border-radius: 8px;
  margin: 12px 30px 13px 0;
  cursor: pointer;
}

.popup_main-container .popup_container .popup_left-side .popup_terms {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #abaeb3;
  max-width: 302px;
}

.popup_main-container .popup_container .popup_left-side .popup_terms a {
  text-decoration: underline;
  color: #abaeb3;
}

.popup_main-container .popup_container .popup_left-side .popup_input {
  font-size: 16px;
  line-height: 20px;
  color: black;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-right: 30px;
  height: 46px;
  border-color: #e5e9f2;
  border-radius: 8px;
  width: 321px;
}

.popup_main-container .popup_container .popup_left-side .popup_input_flag {
  background: none;
  border-right-color: #e5e9f2;
}

.popup_main-container .popup_container .popup_left-side .confirm_icon_container {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 80px 0;
  overflow: hidden;
}

.popup_main-container .popup_container .popup_left-side .confirm_icon_item {
  z-index: 100;
  padding: 5px;

}

.popup_main-container .popup_container .popup_left-side .circle {
  border-radius: 50%;
  width: 180px;
  height: 180px;
  background-color: lightgreen;
  position: absolute;
  opacity: 0;

  animation: scaleIn 2s infinite cubic-bezier(.36, .11, .89, .32);
}

@keyframes scaleIn {
  from {
    transform: scale(.3, .3);
    opacity: .5;
  }

  to {
    transform: scale(1.4, 1.4);
    opacity: 0;
  }
}

.popup_main-container .popup_container .popup_left-side .popup_logo {
  padding-top: 87px;
  display: flex;
  align-items: center;
}

.popup_main-container .popup_container .popup_left-side .popup_logo img {
  width: 65px;
}

.popup_main-container .popup_container .popup_left-side .popup_logo p {
  font-size: 10px;
  color: #77808D;
  padding-right: 5px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .popup_main-container .popup_container .popup_left-side {
    padding-top: 80px;
    padding-left: 32px;
  }

  .popup_main-container .popup_container .popup_left-side .popup_terms {
    max-width: 250px;
  }

  .popup_main-container .popup_container .popup_left-side .popup_input,
  .popup_main-container .popup_container .popup_left-side .popup_button {
    width: 250px;
  }

  .popup_main-container .popup_container .popup_left-side .popup_logo {
    padding-top: 40px;
  }

  .popup_main-container .popup_container .popup_right-side {
    margin-left: 16px;
    display: block;
  }

  .popup_main-container .popup_container .popup_right-side img {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}

@media (max-width: 576px) {
  .popup_main-container .popup_container .popup_left-side .popup_logo {
    padding-top: 20px;
  }

  .popup_main-container .popup_container .popup_left-side {
    padding-top: 40px;
    padding-left: 16px;
  }

  .popup_main-container .popup_container .popup_left-side .popup_header {
    font-size: 22px;
    line-height: 24px;
  }

  .popup_main-container .popup_container .popup_left-side .popup_description {
    margin-top: 0;
    max-width: 180px;
  }

  .popup_main-container .popup_container .popup_left-side .popup_input,
  .popup_main-container .popup_container .popup_left-side .popup_button,
  .popup_main-container .popup_container .popup_left-side .popup_terms {
    max-width: 200px;
  }

  .popup_main-container .popup_container .popup_right-side {
    margin-left: 4px;
  }
}