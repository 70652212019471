@font-face {
  font-family: 'DDC Heading Font Face';
  src: url('./DDC/webfont.woff2') format('woff2'),
       url('./DDC/webfont.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Volvo Novum';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Volvo/volvo-novum-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Volvo Novum';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./Volvo/volvo-novum-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'BMW Type Next';
  font-weight: 300;
  font-display: swap;
  src: url('./BMW/BMWTypeNextLatinTT-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'BMW Type Next';
  font-weight: 400;
  font-display: swap;
  src: url('./BMW/BMWTypeNextLatinTT-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'BMW Type Next';
  font-weight: 700;
  font-display: swap;
  src: url('./BMW/BMWTypeNextLatinTT-Bold.woff2') format('woff2');
}
