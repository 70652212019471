@font-face {
  font-family: SFPro;
  src: url("fonts/SFPro-Black.otf");
  s-weight: black;
  s-style: normal;
}
@font-face {
  font-family: SFPro;
  src: url("fonts/SFPro-Bold.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: SFPro;
  src: url("fonts/SFPro-Semibold.otf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: SFPro;
  src: url("fonts/SFPro-Medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: SFPro;
  src: url("fonts/SFPro-Regular.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: SFPro;
  src: url("fonts/SFPro-Light.otf");
  font-weight: 300;
  font-style: normal;
}
body {
  overscroll-behavior-y: contain;
  overscroll-behavior: contain;
}
* {
  box-sizing: border-box;
}
.phone-under-nav-wrapper {
  position: relative;
  background-color: #fff;
  border-radius: 40px 40px 0 0;
  padding: 10px;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1000;
  overscroll-behavior: contain;
  touch-action: none;
  animation: MoveUpDown 0.3s linear;
  font-family: Arial, Helvetica, sans-serif;
}
.phone-under-nav-wrapper.phone-under-nav-wrapper-dark {
  background-color: #352A72;
}
.phone-under-nav-wrapper.phone-under-nav-wrapper-dark .title-item .title {
  color: #fff;
}
.phone-under-nav-wrapper.phone-under-nav-wrapper-dark .note-item .note {
  color: #fff;
}
.phone-under-nav-wrapper.phone-under-nav-wrapper-dark .nav-footer .footer-first-item {
  color: #fff;
  display: flex;
  justify-content: center;
}
.phone-under-nav-wrapper.phone-under-nav-wrapper-dark .nav-footer .footer-first-item .app-name-dark .anticon {
  width: 70.21px;
  height: 16.24px;
}
.phone-under-nav-wrapper.phone-under-nav-wrapper-dark .nav-footer .footer-first-item .app-name-dark .anticon svg {
  width: 100%;
  height: 100%;
}
.phone-under-nav-wrapper.phone-under-nav-wrapper-dark .nav-footer .footer-last-item {
  color: #fff;
}
.phone-under-nav-wrapper.phone-under-nav-wrapper-dark .nav-footer .footer-last-item a {
  color: #5B66EA;
}
.phone-under-nav-wrapper .nav-header-border {
  background-color: #f0f1f4;
  border-radius: 9px;
  height: 6px;
  width: 44px;
}
.phone-under-nav-wrapper .logo-item {
  margin: 45px 0 29px 0;
  width: 189px;
}
.phone-under-nav-wrapper .logo-item img {
  width: 100%;
  object-fit: contain;
}
.phone-under-nav-wrapper .title-item {
  max-width: 98%;
  margin: 0 auto;
}
.phone-under-nav-wrapper .title-item .title {
  width: 100%;
  white-space: normal;
  word-break: break-word;
  font-weight: bold;
  line-height: 22px;
  font-size: 23px;
  letter-spacing: 0.86px;
}
.phone-under-nav-wrapper .note-item {
  max-width: 90%;
  margin: 0 auto;
  margin-top: 11px;
}
.phone-under-nav-wrapper .note-item .note {
  width: 100%;
  white-space: normal;
  word-break: break-word;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
}
.phone-under-nav-wrapper .button-item {
  width: 325px;
  margin: 0 auto;
  margin-top: 23px;
  padding: 0 3px;
}
.phone-under-nav-wrapper .button-item .safari-button {
  height: 70px;
  width: 100%;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-weight: 600;
  z-index: 10;
}
.phone-under-nav-wrapper .button-item .swipe-to-start-button {
  width: 100%;
  height: 70px;
  position: relative;
  background-color: #5B66EA;
  border-radius: 100px;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 10;
}

.phone-under-nav-wrapper .button-item .swipe-to-start-button-animation {
	animation: nudge 3s ease-in-out infinite;
}
.phone-under-nav-wrapper .button-item .swipe-to-start-button .rc-swipeout-content {
  background-color: transparent;
  width: 100%;
}
.phone-under-nav-wrapper .button-item .swipe-to-start-button .rc-swipeout-btn .rc-swipeout-btn-text {
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: 600;
  padding-left: 56px;
  padding-right: 0;
  opacity: 0;
}
.phone-under-nav-wrapper .button-item .swipe-to-start-button .icon-with-text {
  padding: 7px;
  border-radius: 100px;
  display: flex;
  align-items: center;
}
.phone-under-nav-wrapper .button-item .swipe-to-start-button .icon-with-text .icon-wrapper {
  height: 56px;
  min-width: 56px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  position: absolute;
  animation: linear infinite;
  animation-name: run;
  animation-duration: 2.5s;
}
.phone-under-nav-wrapper .button-item .swipe-to-start-button .icon-with-text .text {
  text-align: center;
  color: #fff;
  font-weight: 600;
  width: 100%;
}
.phone-under-nav-wrapper .nav-footer {
  margin-top: 30px;
  margin-bottom: 5px;
}
.phone-under-nav-wrapper .nav-footer .footer-first-item {
  font-size: 8px;
  color: #474657;
  font-weight: 400;
  line-height: 10px;
  display: flex;
  justify-content: center;
}
.phone-under-nav-wrapper .nav-footer .footer-first-item a {
  margin-left: 5px;
  display: block;
}
.phone-under-nav-wrapper .nav-footer .footer-last-item {
  font-size: 8px;
  color: #474657;
  font-weight: 400;
  line-height: 10px;
  margin-top: 5px;
}
.phone-under-nav-wrapper .nav-footer .footer-last-item a {
  color: #5B66EA;
  text-decoration: none;
  margin-left: 2px;
}
@keyframes MoveUpDown {
  0% {
    transform: translateY(1000px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes run {
  0% {
    left: 5px;
  }
  40% {
    left: 5px;
  }
  60% {
    left: 30px;
  }
  70% {
    left: 30px;
  }
  100% {
    left: 5px;
  }
}

@keyframes nudge {
	0% {
			transform: rotate(0deg);
	}
	10% {
			transform: rotate(0deg);
	}
	25% {
			transform: rotate(-2deg);
	}
	50% {
			transform: rotate(2deg);
	}
	75% {
			transform: rotate(0deg);
	}
	100% {
			transform: rotate(0deg);
	}
}

.matadot-live-chat-123789-write-text-message {
  padding: 10px;
  position: relative;
  padding-bottom: 0;
}
.matadot-live-chat-123789-write-text-message .react-tel-input .selected-flag:hover, .matadot-live-chat-123789-write-text-message .react-tel-input .selected-flag:focus {
  background-color: transparent;
}
.matadot-live-chat-123789-write-text-message .flag-dropdown.open {
  background: transparent;
}
.matadot-live-chat-123789-write-text-message .react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
}
.matadot-live-chat-123789-write-text-message .react-tel-input .flag-dropdown {
  border-radius: 100px 0 0 100px;
}
.matadot-live-chat-123789-write-text-message svg {
  position: absolute;
  top: 27px;
  z-index: 1;
  left: 23px;
}
.matadot-live-chat-123789-write-text-message .matadot-live-chat-123789-main-input {
  height: 54px;
  border: none;
  background-color: #f4f7fc !important;
  border-radius: 100px;
  padding-right: 110px !important;
  padding-left: 50px !important;
  width: 100%;
  font-size: 14px;
  box-shadow: none;
  margin: 0 !important;
  box-sizing: border-box;
}
.matadot-live-chat-123789-write-text-message .matadot-live-chat-123789-main-input:focus {
  outline: none;
}
.matadot-live-chat-123789-write-text-message .matadot-live-chat-123789-main-button {
  border-radius: 100px;
  position: absolute;
  top: 17px;
  right: 17px;
  color: #fff;
  transition: background-color 0.3s ease-in-out;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  height: 40px;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
}
.matadot-live-chat-123789-write-text-message .matadot-live-chat-123789-main-button:focus {
  outline: none;
}

.fade-in {
  animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    display: none;
  }
  to {
    opacity: 1;
  }
}
